<template>
  <div>
    <modal-edit-channels
      v-if="showEditChannels"
      :showModal="showEditChannels"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="locationId > 0"
      :editFunction="setShowEditChannels"
    >
      <template v-slot:title>Channel publicatie</template>
    </ui-section-header>

    <message
      :message="'Specify on what channels the option should be available'"
    />

    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Active</th>
          <th>Channel</th>
          <th v-if="locationId > 0">Is public</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="channel in channels" :key="channel.ChannelId">
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="checkOptionOnChannel(channel.ChannelId)"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!checkOptionOnChannel(channel.ChannelId)"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td>
            <span>{{ channel.ChannelName }}</span>
          </td>
          <td v-if="locationId > 0">
            <font-awesome-icon
              v-if="checkOptionActiveOnChannel(channel.ChannelId)"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!checkOptionActiveOnChannel(channel.ChannelId)"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalEditOptionChannels = () =>
  import('@/components/Options/ModalEditOptionChannels')

export default {
  components: {
    message: Message,
    'modal-edit-channels': ModalEditOptionChannels,
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showEditChannels: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    channels() {
      let channels = []

      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channel.Id
        )
      } else {
        if (this.channel) {
          channels.push({
            ChannelId: this.channel.Id,
            ChannelName: this.channel.Name,
          })
        }
      }

      return channels
    },
  },

  created() {},

  methods: {
    checkOptionOnChannel(channelId) {
      let result = false

      let channelIndex = this.option.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = true
      }

      if (
        this.option.SupplierType === 'Channel' &&
        this.option.SupplierId === channelId
      ) {
        result = true
      }

      return result
    },

    checkOptionActiveOnChannel(channelId) {
      let result = false

      let channelIndex = this.option.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = this.option.Channels[channelIndex].IsPublic
      }

      return result
    },

    setShowEditChannels() {
      this.showEditChannels = true
    },

    hideModal() {
      this.showEditChannels = false
    },
  },
}
</script>

<style></style>
